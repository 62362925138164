<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Reservas desde la administración
            </h5>
          </div>
          <div class="d-flex justify-content-between m-3">
            <div class="col-3">
              <div class="input-group">
                <input v-model="misearch" type="text" class="form-control input-icon"
                       placeholder="Buscar Reserva" aria-label="Recipient's username"
                       aria-describedby="button-addon2" autocomplete="new-password">
              </div>
            </div>
            <div class="col-9 text-end">
              <button v-if="tienePermiso('gestionar_reservas')" @click="openSeguridad"
                      class="btn btn-dark align-content-end"
                      type="submit">Nueva reserva
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" style="font-size: 0.875rem !important;">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Estado</th>
                <th>#</th>
                <th>Fecha</th>
                <th>Cliente</th>
                <th>Categoria</th>
                <th>Sucursal</th>
                <th>Salida - Regreso</th>
                <th>Pagado</th>
                <th>Agente</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in items" :key="item.id"
                  class="text-center align-middle"
                  :class="item.revisado?'':'text-bold'">
                <td>
                  <span v-if="!item.revisado" class="bg-info text-white p-1 rounded-3">Nuevo</span>
                  <span v-else :class="getClassByPaymentStatus(item.estado)"
                        class="text-white p-1 rounded-3">{{ item.estado }}</span>
                </td>
                <td class="text-wrap" :class="item.estado==='Cancelado'?'text-danger':''">
                  {{ item.noReserva }}
                </td>
                <td class="text-wrap">{{ formatearFecha(item.fecha) }}</td>
                <td class="text-wrap">{{ item.nombre ? item.nombre : "" }} {{ item.apellidos }}</td>
                <td class="text-wrap">{{ item.categoria ? item.categoria.name : "" }}</td>
                <td class="text-wrap">{{ item.sucursal ? item.sucursal.name : "" }}</td>
                <td class="text-wrap">
                  {{ item.fechaRenta ? formatearFecha(item.fechaRenta) : "" }} -<br>
                  {{ item.fechaFin ? formatearFecha(item.fechaFin) : "" }}
                </td>
                <td class="text-wrap">
                  {{ item.estado === "No pagado" ? "Solo reserva" : "$ " + numeroFormateado(item.total) + " MXN" }}
                </td>
                <td class="text-wrap">
                  {{ item.agente.email }}
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button @click="openDetalles(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Ver detalles de la reserva" data-container="body"
                            data-animation="true"
                            class="btn btn-info p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                    <div v-if="tienePermiso('gestionar_reservas')">
                      <button v-show="item.estado!=='Cancelado'" v-if="item.estado!=='Contratado'"
                              @click="openUpdate(this.DecodeId(item.id))"
                              data-bs-toggle="tooltip" data-bs-placement="top" title="Editar reserva"
                              data-container="body" data-animation="true" class="btn btn-info p-1 ms-1">
                        <i class="material-icons opacity-10">edit</i></button>
                    </div>
                    <div v-if="tienePermiso('cancelar_reservas')">
                      <button v-show="item.estado!=='Cancelado'" v-if="item.estado!=='Contratado'"
                              @click="Cancelar_Reserva(item.id)"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top" title="Cancelar la reserva" data-container="body"
                              data-animation="true"
                              class="btn btn-danger p-1 ms-1">
                        <i class="material-icons opacity-10">cancel</i></button>
                    </div>
                    <button @click="generateReport(item)" data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Generar reserva" data-container="body" data-animation="true"
                            class="btn btn-secondary p-1 ms-1">
                      <i class="material-icons opacity-10">description</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing pt-2 pb-2">
      <div class="row">
        <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
          <!--          Mostrando {{ items.length }} de-->
          <!--          {{ cant }}-->
        </div>
        <div class="col-lg-6 d-flex align-items-center justify-content-center text-dark">
          <p class="me-3" style="margin-bottom: 0">Elementos por página</p>
          <select class="form-control" style="width: 50px; height: 40px; padding: 5px" v-model="cant">
            <option :value=10>10</option>
            <option :value=50>50</option>
            <option :value=100>100</option>
          </select>
        </div>
        <div class="col-lg-3 d-flex align-items-center justify-content-center text-dark">
          <button class="btn btn-dark me-3" style="height: 40px" @click="prevPage"
                  :disabled="this.hasPreviousPage===false">
            <i class="material-icons opacity-10">skip_previous</i></button>
          <button class="btn btn-dark" style="height: 40px" @click="nextPage" :disabled="this.hasNextPage===false">
            <i class="material-icons opacity-10">skip_next</i></button>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal DetallesReserva">
    <div v-if="showDetalles" :class="['modal', { 'show': showDetalles }]"
         @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="row mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles de la reserva<i @click="closeDetalles"
                                                                                         class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row mb-3">
          <div class="col-lg-6 col-sm-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">No. Reserva:</strong> &nbsp;
                {{ this.item.noReserva }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Nombre:</strong> &nbsp;
                {{ this.item.nombre }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Email:</strong> &nbsp;
                {{ this.item.email }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Teléfono:</strong> {{ this.item.telefono }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Fecha de Renta:</strong><br>
                {{ formatearFecha(this.item.fechaRenta) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Días:</strong> &nbsp;
                {{ this.item.dias }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Cantidad de vehículos:</strong> &nbsp;
                {{ this.item.cantidad }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Cobertura {{ item.tipoCobertura }}:</strong> &nbsp;
                $ {{ numeroFormateado(this.item.tarifaDiaria) }} MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total de renta: </strong>$ {{ numeroFormateado(this.total_renta) }}
                MXN
              </li>

            </ul>
          </div>
          <div class="col-lg-6 col-sm-12">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Procedencia:</strong> &nbsp;
                {{ this.item.origen === "portal" ? "Portal web" : this.item.origen }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Apellidos:</strong> &nbsp;
                {{ this.item.apellidos }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Edad:</strong> &nbsp; {{ this.item.edad }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Sucursal:</strong> &nbsp; {{ this.item.sucursal.name }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Fecha de Regreso:</strong><br>
                {{ formatearFecha(this.item.fechaFin) }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Categoría:</strong> &nbsp; {{ this.item.categoria.name }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Forma de
                  pago: </strong>{{ this.item.metodoPago === "efectivo" ? "Efectivo" : "Tarjeta" }}
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total de servicios extras: </strong>$
                {{ numeroFormateado(parseFloat(this.item.serviciosExtras) * parseInt(item.cantidad)) }}
                MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total (Impuestos incluidos): </strong>$ {{ numeroFormateado(this.monto_total)
                }} MXN
              </li>
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Total pagado: </strong>$ {{ numeroFormateado(this.item.total) }} MXN
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Comentarios: </strong>{{ this.item.comentarios ? this.item.comentarios : "" }}
              </li>
            </ul>
          </div>
          <div class="col-6">
            <ul class="list-group">
              <li class="text-sm border-0 list-group-item ps-0">
                <strong class="text-dark">Servicios extras: </strong>
                <ul>
                  <li v-for="item in services_ex" :key="item.id">{{ item.servicio.name }}</li>
                  <li v-if="this.item.extraservicereserveSet.length>0">
                    {{ this.item.extraservicereserveSet[0].description }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="closeDetalles" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Seguridad">
    <div v-if="seguridad" :class="['modal', { 'show': seguridad }]" @transitionend="onTransitionEnd">
      <div class="modal-content1">
        <div class="row mb-3 border-bottom border-dark">
          <h6 class="text-start"><i class="fa fa-lock me-2"></i>Escriba su contraseña<i @click="closeSeguridad"
                                                                                        class="material-icons-round opacity-10 modal-icon">close</i>
          </h6>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <input v-model="password" @focus="misearch=''" type="password" autocomplete="new-password"
                   class="form-control">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe escribir su contraseña</p></div>
            </div>
            <div v-show="error_seguridad" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Contraseña incorrecta</p></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex justify-content-start">
            <button @click="closeSeguridad" class="btn btn-secondary" type="button">Cerrar</button>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <button @click="Comprobar" class="btn btn-dark" type="button">Aceptar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Nueva">
    <div v-if="showModal" :class="['modal', { 'show': showModal }]" @transitionend="onTransitionEnd">
      <div class="modal-content mt-sm-3">
        <div class="row ps-2 pe-2 mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>
            Nueva reserva
            <i @click="closeModal" class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_uno">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 fecha_renta">
            <label class="form-label">Fecha de renta<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es" placeholder="Seleccione una fecha"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                           v-model="fechaRenta" class="form_fecha" :class="fechaRenta_error?'error_fecha':''"
                           format="dd/MM/yyyy hh:mm aa">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 fecha_regreso">
            <label class="form-label">Fecha de regreso<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es" placeholder="Seleccione una fecha"
                           minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                           v-model="fechaFin" class="form_fecha" :class="fechaFin_error?'error_fecha':''"
                           format="dd/MM/yyyy hh:mm aa">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 dias">
            <label class="form-label">Días<span class="text-danger ms-1">*</span></label>
            <input v-model="dias" disabled type="number" class="form-control p-2 border border-secondary"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 categoria">
            <label class="form-label">Categoría<span class="text-danger ms-1">*</span></label>
            <select :disabled="deshabilitar_categoria" v-model="categoria"
                    class="form-select" v-bind:class="categoria_error?'is-invalido':''">
              <option selected disabled :value="null">Seleccione una categoria</option>
              <option v-for="item in allCategoriasAdmin" v-bind:class="categorias_id.includes(item.id)?'':'text-danger'"
                      :value="item.id" :key="item.id">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 cobertura">
            <label class="form-label">Tipo de cobertura<span class="text-danger ms-1">*</span></label>
            <select v-model="tipo" class="form-select">
              <option selected disabled :value="0">Seleccione una tarifa</option>
              <option value="total">Cobertura total : ${{ numeroFormateado(tarifa_total) }} MXN</option>
              <option value="tradicional">Cobertura tradicional : ${{ numeroFormateado(tarifa_tradicional) }} MXN
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 tarifa">
            <label class="form-label">Tarifa<span class="text-danger ms-1">*</span></label>
            <input v-model.lazy="tarifa" v-money="money" class="form-control"
                   :class="tarifa_vacia_error?'is-invalid':''" />
          </div>
          <!--          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 cantidad">-->
          <!--            <label class="form-label">Cantidad<span class="text-danger ms-1">*</span></label>-->
          <!--            <select v-model="cantidad" class="form-select" :class="cantidad_error?'is-invalido':''">-->
          <!--              <option selected disabled :value="0">Seleccione la cantidad de vehículos</option>-->
          <!--              <option v-for="item in cantidad_vehiculos" :value="item" :key="item">{{ item }}</option>-->
          <!--            </select>-->
          <!--          </div>-->
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 Agente">
            <label class="form-label">Agente<span class="text-danger ms-1">*</span></label>
            <input class="form-control mb-3" :value="this.$store.state.username" disabled>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 sucursal">
            <label class="form-label">Sucursal<span class="text-danger ms-1">*</span> </label>
            <select v-bind:disabled="this.$store.state.rol!=='Super Administrador'"
                    :class="sucursal_error?'text-gray is-invalido':''" v-model="sucursal"
                    class="form-select mb-3">
              <option selected disabled value="0">Seleccione una Sucursal</option>
              <option v-for="item in allSucursales" :value="item.id" v-show="item.isActive">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="tarifa_error" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">La tarifa debe ser mayor o igual que la cobertura</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar todos los campos</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="error_fechas" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Error en las fechas</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">El número de teléfono es inválido</p></div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
            <button @click="closeModal" class="btn btn-secondary" type="button">Cancelar</button>
            <button @click="Pagina_2" class="btn btn-dark" type="button">Siguiente</button>
          </div>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_dos">
          <div class="row mb-2 services_extras_container">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 services_extras mb-2 shadow rounded"
                 v-for="item in elementosPaginaActual" :key="item.id">
              <div class="d-flex align-items-center">
                <img alt="icono" :src="getURL(item.icono)" class="img-fluid me-3" style="height: 35px; width: 35px">
                <span>{{ item.name }}</span>
              </div>
              <div class="d-flex align-items-center">
                <a v-if="servicios_reservados.includes(item.id)" class="btn btn-primary1 mb-0"
                   style="width: 315px"
                   @click="this.Quitar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-minus-square"></i> Quitar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
                <a v-else class="btn btn-issy mb-0"
                   style="width: 315px"
                   @click="this.Adicionar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-plus-square"></i> Adicionar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button @click="paginaAnterior" class="btn btn-dark me-3"><i class="material-icons opacity-10">arrow_back_ios</i>
            </button>
            <button v-for="index in totalPaginas" :key="index" @click="irPaginaActual(index)"
                    class="btn me-3" :class="index===paginaActual?'btn-info':'btn-secondary'">{{ index }}
            </button>
            <button @click="paginaSiguiente" class="btn btn-dark"><i
              class="material-icons opacity-10">arrow_forward_ios</i></button>
          </div>
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ps-2 pe-2 mb-2 shadow rounded">
              <h5 class="text-center">Otro servicio</h5>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Descripción</label>
                  <input type="text" class="form-control p-2 border border-secondary"
                         v-model="description_extra_service">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Precio</label>
                  <input type="number" class="form-control p-2 border border-secondary" v-model="precio_otro">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="form-check p-0">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="pago_diario"
                      v-model="diario_otro"
                    />
                    <label for="pago_diario" class="h6 text-bold mb-1">Pago diario</label>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="d-flex align-items-center">
                    <a v-if="extra_service_add" :id="'adicionar'+'otro'" class="btn w-100 btn-issy mb-0"
                       @click="this.Adicionar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-plus-square"></i> Adicionar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                    <a v-else :id="'quitar'+'otro'" class="btn w-100 btn-primary1 mb-0"
                       @click="this.Quitar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-minus-square"></i> Quitar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between mt-3">
              <button @click="Pagina_1" class="btn btn-secondary" type="button">Regresar</button>
              <button @click="Pagina_3" class="btn btn-dark" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_tres">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 nombre">
            <label class="form-label">Nombre<span class="text-danger ms-1">*</span></label>
            <div class="input-container">
              <input type="text" v-model="nombre" :class="nombre_error?'is-invalido':''"
                     @input="autocomplete" class="form-control dropdown mb-3">
              <span class="clear-icon" @click="Clear_Data" v-show="nombre!==''">
                            <i class="fas fa-times text-danger"></i>
                          </span>
            </div>
            <ul v-if="showSubmenu" class="position-absolute z-index2 bg-light">
              <li class="dropdown-item cursor-pointer d-block" v-for="item in autocompleteItems"
                  :key="item.id"
                  @click="selectItem(item)">{{ item.nombre }} {{ item.apellidos }}
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 apellidos">
            <label class="form-label">Apellidos<span class="text-danger ms-1">*</span></label>
            <input :disabled="deshabilitado" v-model="apellidos" type="text" :class="apellidos_error?'is-invalido':''"
                   class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 correo">
            <label class="form-label">Correo<span class="text-danger ms-1">*</span></label>
            <input v-model="email" type="email" :class="correo_error?'is-invalido':''"
                   class="form-control">
            <div v-show="email_error" class="text-danger text-sm mt-3 text-center p-1"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark" style="font-size: 10px">El correo es inválido</p></div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 edad">
            <label class="form-label">Edad<span class="text-danger ms-1">*</span></label>
            <input v-model="edad" type="number" :class="edad_error?'is-invalido':''"
                   class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 telefono">
            <label class="form-label">Teléfono<span class="text-danger ms-1">*</span></label>
            <MazPhoneNumberInput
              v-model="telefono"
              v-model:country-code="cod_telefono"
              show-code-on-list
              :error="telefono_error"
              :preferred-countries="['MX', 'CU', 'US']"
              no-radius
              noBorder="false"
              :translations="{
                      countrySelector: {
                        placeholder: 'Código de país',
                        error: 'Seleccione un país',
                        searchPlaceholder: 'Buscar un país',
                      },
                      phoneInput: {
                        placeholder: 'Número de teléfono',
                        example: 'Ejemplo:',
                      },
                    }"
            />
            <div v-show="phone_error" class="text-danger mt-3 text-center p-1"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark" style="font-size: 10px">El número de teléfono es inválido</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 tarifa_renta">
            <label class="form-label">Tarifa de renta<span class="text-danger ms-1">*</span></label>
            <input readonly v-model.lazy="tarifa_renta" v-money="money" class="form-control" />
            <div v-show="tarifa_error" class="text-danger text-sm mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08); font-size: 10px">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">La tarifa debe ser mayor o igual que la cobertura</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 total_extras">
            <label class="form-label">Total de servicios extras<span class="text-danger ms-1">*</span></label>
            <input readonly v-model.lazy="total_extra_info" v-money="money" class="form-control" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 total">
            <label class="form-label">Total (impuestos incluidos)<span class="text-danger ms-1">*</span></label>
            <input readonly v-model.lazy="total" v-money="money" class="form-control" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 forma_pago">
            <label class="form-label">Forma de pago<span class="text-danger ms-1">*</span></label>
            <select class="form-select mb-3" v-model="tipo_pago" :class="tipo_pago_error?'is-invalido':''">
              <option value="tarjeta">Tarjeta</option>
              <option value="efectivo">Efectivo</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 total_pagado">
            <label class="form-label">Total pagado<span class="text-danger ms-1">*</span></label>
            <input v-model.lazy="total_pagado" v-money="money" class="form-control" />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3 comentarios">
            <label class="form-label">Comentarios</label>
            <textarea class="form-control" v-model="comentarios"></textarea>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar todos los campos</p></div>
            </div>
            <div v-show="msg_create" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">{{ msg_create_text }}</p></div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
            <button @click="Pagina_2" class="btn btn-secondary" type="button">Regresar</button>
            <button @click="Guardar" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal Actualizar">
    <div v-if="showUpdate" :class="['modal', { 'show': showUpdate }]" @transitionend="onTransitionEnd">
      <div class="modal-content mt-sm-3">
        <div class="row ps-2 pe-2 mb-3 border-bottom border-dark">
          <h4 class="text-start"><i class="fa fa-plus-square me-2"></i>Editar reserva - {{ this.id }}<i
            @click="closeUpdate"
            class="material-icons-round opacity-10 modal-icon">close</i>
          </h4>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_uno">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 fecha_renta">
            <label class="form-label">Fecha de renta<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                           placeholder="Seleccione una fecha" minutes-increment="60"
                           v-model="fechaRenta" class="form_fecha" :class="fechaRenta_error?'error_fecha':''"
                           no-minutes-overlay :start-time="{minutes: 0}" format="dd/MM/yyyy hh:mm aa">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 fecha_regreso">
            <label class="form-label">Fecha de regreso<span class="text-danger ms-1">*</span></label>
            <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                           placeholder="Seleccione una fecha" minutes-increment="60"
                           v-model="fechaFin" class="form_fecha" :class="fechaFin_error?'error_fecha':''"
                           no-minutes-overlay :start-time="{minutes: 0}" format="dd/MM/yyyy hh:mm aa">
            </VueDatePicker>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 dias">
            <label class="form-label">Días<span class="text-danger ms-1">*</span></label>
            <input v-model="dias" readonly type="number" class="form-control p-2 border border-secondary"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 categoria">
            <label class="form-label">Categoría<span class="text-danger ms-1">*</span></label>
            <select disabled v-model="categoria"
                    class="form-select" :class="categoria_error?'is-invalido':''">
              <option selected disabled :value="null">Seleccione una categoria</option>
              <option v-for="item in allCategoriasAdmin" v-bind:class="categorias_id.includes(item.id)?'':'text-danger'"
                      :value="item.id" :key="item.id">{{ item.name }}
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 cobertura">
            <label class="form-label">Tipo de cobertura<span class="text-danger ms-1">*</span></label>
            <select v-model="tipo" class="form-select">
              <option selected disabled :value="0">Seleccione una tarifa</option>
              <option value="total">Cobertura total : ${{ numeroFormateado(tarifa_total) }}MXN</option>
              <option value="tradicional">Cobertura tradicional : ${{ numeroFormateado(tarifa_tradicional) }}MXN
              </option>
            </select></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 tarifa">
            <label class="form-label">Tarifa<span class="text-danger ms-1">*</span></label>
            <input v-model.lazy="tarifa" v-money="money" class="form-control"
                   :class="tarifa_vacia_error?'is-invalid':''" />
          </div>
          <!--          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 cantidad">-->
          <!--            <label class="form-label">Cantidad<span class="text-danger ms-1">*</span></label>-->
          <!--            <input v-model="cantidad" class="form-control" :class="cantidad_error?'is-invalido':''">-->
          <!--          </div>-->
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 Agente">
            <label class="form-label">Agente<span class="text-danger ms-1">*</span></label>
            <input class="form-control mb-3" :value="this.$store.state.username" readonly>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 sucursal">
            <label class="form-label">Sucursal<span class="text-danger ms-1">*</span> </label>
            <select v-bind:disabled="this.$store.state.rol!=='Super Administrador'"
                    :class="sucursal_error?'text-gray is-invalido':''" v-model="sucursal"
                    class="form-select mb-3">
              <option selected disabled value="0">Seleccione una Sucursal</option>
              <option v-for="item in allSucursales" :value="item.id" v-show="item.isActive">{{ item.name }}
              </option>
            </select></div>

          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar todos los campos</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="error_fechas" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Error en las fechas</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="phone_error" class="text-danger mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">El número de teléfono es inválido</p></div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
            <button @click="closeUpdate" class="btn btn-secondary" type="button">Cancelar</button>
            <button @click="Pagina_2" class="btn btn-dark" type="button">Siguiente</button>
          </div>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_dos">
          <div class="row mb-2 services_extras_container">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 services_extras mb-2 shadow rounded"
                 v-for="item in elementosPaginaActual" :key="item.id">
              <div class="d-flex align-items-center">
                <img alt="icono" :src="getURL(item.icono)" class="img-fluid me-3" style="height: 35px; width: 35px">
                <span>{{ item.name }}</span>
              </div>
              <div class="d-flex align-items-center">
                <a v-if="servicios_reservados.includes(item.id)" class="btn btn-primary1 mb-0"
                   style="width: 315px"
                   @click="this.Quitar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-minus-square"></i> Quitar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
                <a v-else class="btn btn-issy mb-0"
                   style="width: 315px"
                   @click="this.Adicionar(item.id, item.precio, item.diario, item.name)">
                  <i class="fa fa-plus-square"></i> Adicionar<span
                  class="ms-2">$ {{ item.precio }} MXN</span>
                  <span v-if="item.diario" class="ms-1">/ día</span>
                  <span v-else class="ms-1">/ único pago</span>
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <button @click="paginaAnterior" class="btn btn-dark me-3"><i class="material-icons opacity-10">arrow_back_ios</i>
            </button>
            <button v-for="index in totalPaginas" :key="index" @click="irPaginaActual(index)"
                    class="btn me-3" :class="index===paginaActual?'btn-info':'btn-secondary'">{{ index }}
            </button>
            <button @click="paginaSiguiente" class="btn btn-dark"><i
              class="material-icons opacity-10">arrow_forward_ios</i></button>
          </div>
          <div class="row">
            <div
              class="col-lg-12 col-md-12 col-sm-12 col-xs-12 ps-2 pe-2 mb-2 shadow rounded">
              <h5 class="text-center">Otro servicio</h5>
              <div class="row">
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Descripción</label>
                  <input type="text" class="form-control p-2 border border-secondary"
                         v-model="description_extra_service">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <label class="form-label">Precio</label>
                  <input type="number" class="form-control p-2 border border-secondary" v-model="precio_otro">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="form-check p-0">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="pago_diario"
                      v-model="diario_otro"
                    />
                    <label for="pago_diario" class="h6 text-bold mb-1">Pago diario</label>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 mb-2">
                  <div class="d-flex align-items-center">
                    <a v-if="extra_service_add" :id="'adicionar'+'otro'" class="btn w-100 btn-issy mb-0"
                       @click="this.Adicionar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-plus-square"></i> Adicionar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                    <a v-else :id="'quitar'+'otro'" class="btn w-100 btn-primary1 mb-0"
                       @click="this.Quitar('otro', precio_otro, diario_otro)">
                      <i class="fa fa-minus-square"></i> Quitar<span
                      class="ms-2">$ {{ precio_otro }} MXN</span>
                      <span class="ms-1">/ {{ diario_otro ? "Día" : "Único pago" }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between mt-3">
              <button @click="Pagina_1" class="btn btn-secondary" type="button">Regresar</button>
              <button @click="Pagina_3" class="btn btn-dark" type="button">Siguiente</button>
            </div>
          </div>
        </div>
        <div class="row ps-2 pe-2" v-show="pagina_tres">
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 nombre">
            <label class="form-label">Nombre<span class="text-danger ms-1">*</span></label>
            <input class="form-control dropdown mb-3" type="text"
                   v-model="nombre" :class="nombre_error?'is-invalido':''"
                   @input="autocomplete">
            <ul v-if="showSubmenu" class="position-absolute z-index2 bg-light">
              <li class="dropdown-item cursor-pointer d-block" v-for="item in autocompleteItems"
                  :key="item.id"
                  @click="selectItem(item)">{{ item.nombre }} {{ item.apellidos }}
              </li>
            </ul>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 apellidos">
            <label class="form-label">Apellidos<span class="text-danger ms-1">*</span></label>
            <input v-model="apellidos" type="text" :class="apellidos_error?'is-invalido':''"
                   class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 correo">
            <label class="form-label">Correo<span class="text-danger ms-1">*</span></label>
            <input v-model="email" type="email" :class="correo_error?'is-invalido':''"
                   class="form-control">
            <div v-show="email_error" class="text-danger text-sm mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">El correo es inválido</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 edad">
            <label class="form-label">Edad<span class="text-danger ms-1">*</span></label>
            <input v-model="edad" type="number" :class="edad_error?'is-invalido':''"
                   class="form-control"></div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 telefono">
            <label class="form-label">Teléfono<span class="text-danger ms-1">*</span></label>
            <MazPhoneNumberInput
              v-model="telefono"
              v-model:country-code="cod_telefono"
              show-code-on-list
              :error="telefono_error"
              :preferred-countries="['MX', 'CU', 'US']"
              no-radius
              noBorder="false"
              :translations="{
                      countrySelector: {
                        placeholder: 'Código de país',
                        error: 'Seleccione un país',
                        searchPlaceholder: 'Buscar un país',
                      },
                      phoneInput: {
                        placeholder: 'Número de teléfono',
                        example: 'Ejemplo:',
                      },
                    }"
            />
            <div v-show="phone_error" class="text-danger text-sm mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">El número de teléfono es inválido</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 tarifa_renta">
            <label class="form-label">Tarifa de renta<span class="text-danger ms-1">*</span></label>
            <input readonly v-model.lazy="tarifa_renta" v-money="money" class="form-control" />
            <div v-show="tarifa_error" class="text-danger text-sm mt-3 text-center p-2"
                 style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">La tarifa debe ser mayor o igual que la cobertura</p></div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 total_extras">
            <label class="form-label">Total de servicios extras<span class="text-danger ms-1">*</span></label>
            <input readonly v-model.lazy="total_extra_info" v-money="money" class="form-control" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 total">
            <label class="form-label">Total (impuestos incluidos)<span class="text-danger ms-1">*</span></label>
            <input readonly v-model.lazy="total" v-money="money" class="form-control" />
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 forma_pago">
            <label class="form-label">Forma de pago<span class="text-danger ms-1">*</span></label>
            <select class="form-select mb-3" v-model="tipo_pago" :class="tipo_pago_error?'is-invalido':''">
              <option value="tarjeta">Tarjeta</option>
              <option value="efectivo">Efectivo</option>
            </select>
          </div>
          <div class="col-sm-12 col-md-6 col-lg-4 mb-3 total_pagado">
            <label class="form-label">Total pagado<span class="text-danger ms-1">*</span></label>
            <input v-model.lazy="total_pagado" v-money="money" class="form-control" />
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3 comentarios">
            <label class="form-label">Comentarios</label>
            <textarea class="form-control" v-model="comentarios"></textarea>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
            <div v-show="error" class="text-danger mt-3 text-center p-2" style="background-color: rgba(255,19,28,0.08)">
              <div class="d-flex align-center justify-content-center"><i class="material-icons-round opacity-10 me-2">error</i>
                <p class="text-dark">Debe llenar todos los campos</p></div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-between">
            <button @click="Pagina_2" class="btn btn-secondary" type="button">Regresar</button>
            <button @click="Actualizar" class="btn btn-dark" type="button">Guardar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="exportar_pdf" :class="['modal', { 'show': exportar_pdf }]" @transitionend="onTransitionEnd">
    <div class="modal-content-contrato">
      <div class="container contrato" ref="contrato_plantilla" id="contrato_open_pdf">
        <div class="row mb-3">
          <div class="col-3">
            <img src="@/assets/img/issyrentallogo.png"
                 style="width: 150px; height: 75px">
          </div>
          <div class="col-7 text-start">
            <p><span class="fw-bold">Dirección:</span> Blvd. Cuauhtemoc Norte 303, Col. Aereopuerto Tijuana, Baja
              California, C.P. 22404</p>
            <p><span class="fw-bold">Correo electrónico:</span> info@issyrental.com</p>
            <p><span class="fw-bold">Teléfono:</span> (664) 361 7721</p>
          </div>
          <div class="col-2 text-center">
            <p>Fecha: {{ formatearFecha(new Date()) }}</p>
            <p>Folio: {{ transaccion.noReserva }}</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 linea">
            <h4>Confirmación de reserva</h4>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4">
            <p>Recibí de:</p>
            <p>{{ transaccion.nombre }} {{ transaccion.apellidos }}</p>
          </div>
          <div class="col-4">
            <p>Teléfono:</p>
            <p>{{ transaccion.telefono }}</p>
          </div>
          <div class="col-4">
            <p>Correo electrónico:</p>
            <p>{{ transaccion.email }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <p>Fechas contratadas de:</p>
            <p>{{ formatearFecha(transaccion.fechaRenta) }}</p>
          </div>
          <div class="col-4">
            <p>Hasta:</p>
            <p>{{ formatearFecha(transaccion.fechaFin) }}</p>
          </div>
          <div class="col-4">
            <p>Hora de entrega:</p>
            <p>{{ formatearFecha(transaccion.fechaFin) }} horas</p>
          </div>
        </div>
        <div class="row linea">
          <div class="col-4">
            <p>Categoría de auto:</p>
            <p>{{ transaccion.categoria.name }}</p>
          </div>
          <div class="col-4">
            <p>Tarifa por día:</p>
            <p>$ {{ transaccion.tarifaDiaria }} MXN</p>
          </div>
          <div class="col-4">
            <p>Días contratados:</p>
            <p>{{ transaccion.dias }} Días</p>
          </div>
        </div>
        <div class="row linea">
          <div class="col-4">
            <p>Tarifas y cargos</p>
          </div>
          <div class="col-5">
            <div class="d-flex justify-content-between">
              <p>Renta de auto: </p>
              <p>$ {{ numeroFormateado(parseFloat(transaccion.tarifaDiaria) * parseInt(transaccion.dias)) }} MXN</p>
            </div>
            <div v-for="item in servicios" class="d-flex justify-content-between">
              <p>{{ item.servicio.name }}:</p>
              <p>$ {{ numeroFormateado(item.servicio.precio) }} MXN</p>
            </div>
            <div class="d-flex justify-content-between">
              <p>Total de extras:</p>
              <p>$ {{ numeroFormateado(transaccion.serviciosExtras) }} MXN</p>
            </div>
          </div>
        </div>
        <div class="row linea">
          <div class="col-4">
          </div>
          <div class="col-5">
            <div class="d-flex justify-content-between">
              <p class="fw-bold">Cargos totales: </p>
              <p>$ {{
                  numeroFormateado(parseFloat(parseFloat(transaccion.tarifaDiaria) * parseInt(transaccion.dias) + parseFloat(transaccion.serviciosExtras)))
                }} MXN</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fw-bold">Cantidad abonada:</p>
              <p>$ {{ numeroFormateado(transaccion.total) }} MXN</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fw-bold">Cantidad por pagar:</p>
              <p>$
                {{
                  numeroFormateado(parseFloat(monto_total) - parseFloat(transaccion.total))
                }}
                MXN</p>
            </div>
            <div class="d-flex justify-content-between">
              <p class="fw-bold">Forma de pago:</p>
              <p>{{ transaccion.metodoPago === "efectivo" ? "Efectivo" : "Tarjeta" }}</p>
            </div>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-8">
            <p class="fw-bold mb-3">Tarifas incluyen</p>
            <ul class="mb-2">
              <li v-for="element in coberturas" :key="element.id" v-show="element.isActive">{{ element.cobertura }}</li>
            </ul>
            <p>Penalización por cancelación fecha: 40% del pago</p>
            <p>Penalización por cancelación hasta 2 días antes 100% del pago</p>
          </div>
          <div class="col-4">
            <p>Salida en oficina</p>
            <p class="mb-3">{{ transaccion.sucursal.address }}</p>
            <p class="fw-bold mb-2">Requisitos para contratar</p>
            <p>Tarjeta de crédito bancaria</p>
            <p>Ser mayor de 25 años</p>
            <p>ID oficial vigente</p>
            <p>Licencia de conducir vigente</p>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-2">
            <div class="linea"></div>
            <p>Juan Carlos Rendón</p>
            <p>Issy Rental</p>
          </div>
          <div class="col-6">

          </div>
          <div class="col-2">
            <div class="linea"></div>
            <p>{{ transaccion.nombre }} {{ transaccion.apellidos }}</p>
            <p>Cliente</p>
          </div>
          <div class="col-2">
          </div>
        </div>
        <div class="row">
          <h6>Términos y condiciones en <a href="https://www.issyrental.com/#/Terminos">https://www.issyrental.com/#/Terminos</a>
          </h6>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <button @click="generateReportPDF" class="btn btn-dark"><i class="fa fa-download me-2"></i> Descargar</button>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import moment from "moment";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import noImagen from "@/assets/img/NoImage.jpg";
import { mapGetters, mapState } from "vuex";
import MazInputPrice from "maz-ui/components/MazInputPrice";
import { VMoney } from "v-money";
import MazPhoneNumberInput from "maz-ui/components/MazPhoneNumberInput";
import html2pdf from "html2pdf.js";
import {
  CoberturasPerTarifaTotal, CoberturasPerTarifaTradicional, createExtraServiceToReserve,
  getReservations,
  getReservationsById,
  getReservationsNext,
  getReservationsPrev
} from "@/actions";
import { decodeBase64 } from "@/util";

const LINK = gql`mutation Link(
          $apellidos: String!,
          $categoria: Int!,
          $email: String!,
          $nombre: String!,
          $sucursal: Int!,
          $telefono: String!,
          $total: Decimal!,
          $comentarios: String!,
          $dias: Int!,
          $edad: Int!,
          $fechaFin: DateTime!,
          $fechaRenta: DateTime!,
          $tarifa: Decimal!,
          $serviciosExtras:Decimal!,
          $tipo:String!,
          $origen:String!,
          $user:Int!,
          $cantidad:Int!,
          $metodo_pago:String!,
          $servicios_reservados:[Int!],
          $cotizacion:Int!
          ) {
          link(
          apellidos: $apellidos
          categoria: $categoria
          email: $email
          nombre: $nombre
          sucursal: $sucursal
          telefono: $telefono
          total: $total
          comentarios: $comentarios
          dias: $dias
          edad: $edad
          fechaFin: $fechaFin
          fechaRenta: $fechaRenta
          origen:$origen
          cantidad:$cantidad
          metodoPago:$metodo_pago
          user:$user
          serviciosExtras:$serviciosExtras
          tarifaDiaria:$tarifa
          tipoCobertura:$tipo
          cotizacion:$cotizacion
          serviciosComprados:$servicios_reservados) {
            success
            url
            error
            reserva
    }
}`;
const UPDATE = gql`mutation UpdateReserva(
        $apellidos: String!,
        $categoria: Int!,
        $email: String!,
        $nombre: String!,
        $sucursal: Int!,
        $telefono: String!,
        $total: Decimal!,
        $comentarios: String!,
        $dias: Int!,
        $edad: Int!,
        $fechaFin: DateTime!,
        $fechaRenta: DateTime!,
        $tarifa: Decimal!,
        $serviciosExtras:Decimal!
        $tipo:String!
		    $user:Int!
		    $cantidad:Int!
        $id:Int!
        $metodo_pago:String!
        $servicios_reservados:[Int!]
) {
    updateReserva(
        apellidos: $apellidos
        categoria: $categoria
        email: $email
        nombre: $nombre
        sucursal: $sucursal
        telefono: $telefono
        total: $total
        comentarios: $comentarios
        dias: $dias
        edad: $edad
        metodoPago:$metodo_pago
        fechaFin: $fechaFin
        fechaRenta: $fechaRenta
        cantidad:$cantidad
        user:$user
        serviciosExtras:$serviciosExtras
        tarifaDiaria:$tarifa
        tipoCobertura:$tipo
        id:$id
        serviciosComprados:$servicios_reservados
    ) {
        success
        error
    }
}`;
const CANCELAR = gql`mutation CancelarReserva($id:Int!, $agente:ID!) {
    cancelarReserva(id: $id, agente:$agente) {
        success
        error
    }
}`;
const REVISADA = gql`
mutation Revisada($id: Int!) {
    revisada(id: $id) {
        success
        errors
    }
}`;
const ALLCATEGORIAS = gql`
query CategoriasDisponiblesAdmin($fechaRenta:DateTime!, $fechaRegreso:DateTime!) {
    categoriasDisponiblesAdmin(fechaSalida: $fechaRenta, fechaRegreso: $fechaRegreso) {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}
`;
const ALLCATEGORIASADMIN = gql`
query AllCategoriasAdmin {
    allCategoriasAdmin {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
    }
}`;
const CANTIDAD = gql`query VehiculosDisponiblesCategoria ($fechaRenta:DateTime!, $fechaRegreso:DateTime!,
    $categoria:Int!) {
    vehiculosDisponiblesCategoria(
    fechaSalida: $fechaRenta,
    fechaRegreso: $fechaRegreso,
    categoria:$categoria)
}`;
const ALLCLIENTE = gql`query AllCliente {
    allCliente {
         id
        nombre
        apellidos
        telefono
        email
        edad
    }
}`;
const ALLSUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
const SERVICIOS = gql`query ServiciosPerName {
    serviciosPerName(name: "") {
        id
        isActive
        name
        diario
        precio
        icono
    }
}`;
const SEGURIDAD = gql`
mutation Login($email:String! $password:String!) {
    login(email: $email password: $password) {
        success
        token
        refreshToken
        error
    }
}`;
const CATEGORIA_ID = gql`
query CategoriaPerId($id:ID!) {
    categoriaPerId(id: $id) {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}`;
const RESERVA_SERVICIO = gql`
query ServicioPorReserva($idReserva:String!, $idServicio:Int!) {
    servicioPorReserva(idReserva: $idReserva, idServicio: $idServicio)
}`;
const TEMPORADA = gql`
query TemporadaAlta($fechaInicio: Date!, $fechaFin: Date!) {
    temporadaAlta(fechaInicio: $fechaInicio, fechaFin: $fechaFin)
}`;
const CLIENTE_EMAIL = gql`
query ClientePerEmail($email:String!) {
    clientePerEmail(email: $email) {
        id
        nombre
        apellidos
        telefono
        email
        edad
        isActive
        direccion
        postal
        telefonoEmergencia
        carnet
        licenciaFile
        pasaporte
        tipo {
            id
            name
            isActive
        }
        ciudad {
            id
            name
            isActive
            estado {
                id
                name
                abreviatura
                isActive
            }
        }
        licencia {
            id
            numero
            vencimiento
            permanente
            estado {
                id
                name
                abreviatura
                isActive
            }
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
}
`;
const RESERVA_BY_ID = gql`
query ReservaById($id:ID!) {
    reservaById(id: $id) {
         id
        total
        email
        tipoCobertura
        dias
        serviciosExtras
        tarifaDiaria
        comentarios
        fechaRenta
        fechaFin
        nombre
        apellidos
        telefono
        edad
        pagoId
        origen
        revisado
        noReserva
        fecha
        estado
        metodoPago
        cantidad
        extraservicereserveSet {
                    id
                    description
                    price
                    daily
                }
        categoria {
            id
            name
            tradicionalAlta
            totalAlta
            tradicionalBaja
            totalBaja
            isActive
            fecha
            image
            iconoWhite
            iconoBlack
            maletas
            maletasSm
            personas
            transmision
            lugar
        }
        sucursal {
            id
            name
            phone
            contactPhone
            address
            postalCod
            openHour
            closeHour
            isActive
        }
        agente {
            id
            username
            firstName
            lastName
            email
            isActive
            phone
        }
    }
}`;
const ALLUSERS = gql`query AllUsers {
    allUsers {
        id
        username
        firstName
        lastName
        email
        isActive
        phone
        groups {
            id
            name
        }
    }
}`;
const SERVICIOS_BY_RESERVE = gql`
query ServicesByReservation($id:ID!) {
    servicesByReservation(id: $id) {
        id
        servicio {
            name
        }
    }
}`;
export default {
  name: "List_Ciudad",
  components: { MazPhoneNumberInput, MaterialButton, VueGoogleAutocomplete, VueDatePicker, MazInputPrice },
  data() {
    return {
      description_extra_service: "",
      extra_service_add: true,
      msg_create: false,
      msg_create_text: "",
      exportar_pdf: false,
      coberturas: [],
      transaccion: null,
      init: false,
      cod_telefono: "",
      total_extra_info: 0,
      money: {
        decimal: ".",
        thousands: ",",
        prefix: "",
        suffix: "",
        precision: 2,
        masked: true
      },
      services_ex: [],
      misearch: "",
      Cargando: false,
      tipo_pago: "",
      item: null,
      allTransacciones: [],
      allCategorias: [],
      allCategoriasAdmin: [],
      allUsers: [],
      categorias_id: [],
      allCliente: [],
      allSucursales: [],
      autocompleteItems: [],
      serviciosPerName: [],
      showSubmenu: false,
      showModal: false,
      showDetalles: false,
      error: false,
      total: 0,
      subtotal: 0,
      apellidos: "",
      categoria: null,
      email: "",
      nombre: "",
      sucursal: 0,
      telefono: "",
      comentarios: "",
      dias: "",
      edad: 0,
      fechaFin: null,
      fechaRenta: null,
      pagina_uno: true,
      pagina_dos: false,
      pagina_tres: false,
      tarifa: 0,
      tarifa_renta: 0,
      tarifa_tradicional: 0,
      tarifa_total: 0,
      phone_error: false,
      cantidad_vehiculos: 1,
      cantidad: 1,
      id: 0,
      showUpdate: false,
      email_error: false,
      car: null,
      total_extras: 0,
      precio_otro: 0,
      diario_otro: false,
      Alta: false,
      renta_auto: 0,
      elementosPorPagina: 3,
      paginaActual: 1,
      totalPaginas: 0,
      cobertura: 0,
      tarifa_error: false,
      seguridad: false,
      password: "",
      error_seguridad: false,
      deshabilitado: false,
      tipo: "total",
      deshabilitar_categoria: false,
      servicios_reservados: [],
      categoria_id: 0,
      categoria_name: "",
      services: [],
      monto_total: 0,

      nombre_error: false,
      apellidos_error: false,
      correo_error: false,
      telefono_error: false,
      edad_error: false,
      sucursal_error: false,

      tarifa_vacia_error: false,
      fechaRenta_error: false,
      fechaFin_error: false,
      cantidad_error: false,
      tipo_pago_error: false,
      categoria_error: false,
      total_pagado: 0,
      error_fechas: false,
      cotizacion: 0,

      hasNextPage: false,
      hasPreviousPage: false,
      startCursor: "",
      lastStartCursor: "",
      endCursor: "",
      lastEndCursor: "",
      cant: 10,
      items: []
    };
  },
  directives: { money: VMoney },
  apollo: {
    allSucursales: {
      query: ALLSUCURSALES,
      fetchPolicy: "cache-and-network"
    },
    allCliente: {
      query: ALLCLIENTE,
      fetchPolicy: "cache-and-network"
    },
    serviciosPerName: {
      query: SERVICIOS,
      fetchPolicy: "cache-and-network"
    },
    allCategoriasAdmin: {
      query: ALLCATEGORIASADMIN,
      fetchPolicy: "cache-and-network"
    },
    allUsers: {
      query: ALLUSERS,
      fetchPolicy: "cache-and-network"
    }
  },
  watch: {
    tipo(value) {
      this.$apollo.query({
        query: CATEGORIA_ID,
        variables: {
          id: this.categoria
        }
      }).then(resp => {
        const categoria = resp.data.categoriaPerId;
        if (!this.init) {
          this.tarifa = parseFloat(this.$store.state.temporada ? value === "total" ? categoria.totalAlta : categoria.tradicionalAlta :
            value === "total" ? categoria.totalBaja : categoria.tradicionalBaja).toFixed(2);
        }
        this.cobertura = parseFloat(this.$store.state.temporada ? value === "total" ? categoria.totalAlta : categoria.tradicionalAlta :
          value === "total" ? categoria.totalBaja : categoria.tradicionalBaja).toFixed(2);
      });
      this.init = false;
    },
    fechaRenta(value) {
      if (this.fechaRenta !== null && this.fechaFin !== null) {
        this.$apollo.query({
          query: ALLCATEGORIAS,
          variables: {
            fechaRenta: this.fechaRenta,
            fechaRegreso: this.fechaFin
          }
        }).then(response => {
          this.allCategorias = response.data.categoriasDisponiblesAdmin;
          this.categorias_id = [];
          this.allCategorias.forEach(item => {
            this.categorias_id.push(item.id);
          });
          if (this.allCategorias.length === 0 && this.$store.state.update_reserva === "") {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "No existen categorías disponibles en esa fecha"
            });
          }
        });
        this.dias = Math.ceil(this.Difechas(this.fechaRenta, this.fechaFin) / 24);
        if (this.tarifa !== 0 && this.dias !== 0) {
          this.total = parseFloat(this.tarifa * this.dias + parseFloat(this.total_extras)).toFixed(2);
          this.renta_auto = parseFloat(this.tarifa * this.dias).toFixed(2);
          this.tarifa_renta = (parseFloat(this.tarifa * this.dias) * parseInt(this.cantidad)).toFixed(2);
          this.Calcular();
        }
      }
      this.Temporada_alta();
      if (value !== null) {
        this.fechaRenta_error = false;
      }
    },
    fechaFin(value) {
      if (this.sucursal !== 0 && this.fechaRenta !== null && this.fechaFin !== null) {
        this.$apollo.query({
          query: ALLCATEGORIAS,
          variables: {
            fechaRenta: this.fechaRenta,
            fechaRegreso: this.fechaFin
          }
        }).then(response => {
          this.allCategorias = response.data.categoriasDisponiblesAdmin;
          this.categorias_id = [];
          this.allCategorias.forEach(item => {
            this.categorias_id.push(item.id);
          });
          if (this.allCategorias.length === 0 && this.$store.state.update_reserva === "") {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              customClass: {
                htmlContainer: "z-index2"
              },
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "No existen categorías disponibles en esa fecha"
            });
          }
        });
        this.dias = Math.ceil(this.Difechas(this.fechaRenta, this.fechaFin) / 24);
        if (this.tarifa !== 0 && this.dias !== 0) {
          this.total = parseFloat(this.tarifa * this.dias + parseFloat(this.total_extras)).toFixed(2);
          this.renta_auto = parseFloat(this.tarifa * this.dias).toFixed(2);
          this.Calcular();
        }
      }
      this.Temporada_alta();
      if (value !== null) {
        this.fechaFin_error = false;
      }
    },
    categoria(val) {
      if (val !== 0) {
        if (this.fechaRenta !== null && this.fechaFin !== null) {
          this.$apollo.query({
            query: CANTIDAD,
            variables: {
              fechaRenta: this.fechaRenta,
              fechaRegreso: this.fechaFin,
              categoria: val
            }
          }).then(response => {
            this.cantidad_vehiculos = response.data.vehiculosDisponiblesCategoria;
          });
        }
        this.$apollo.query({
          query: CATEGORIA_ID,
          variables: {
            id: val
          }
        }).then(resp => {
          const value = resp.data.categoriaPerId;
          this.tarifa_tradicional = parseFloat(this.$store.state.temporada ? value.tradicionalAlta : value.tradicionalBaja).toFixed(2);
          this.tarifa_total = parseFloat(this.$store.state.temporada ? value.totalAlta : value.totalBaja).toFixed(2);
          this.cobertura = parseFloat(this.tipo === "total" ? this.tarifa_total : this.tarifa_tradicional).toFixed(2);
          if (!this.init) {
            this.tarifa = parseFloat(this.tipo === "total" ? this.tarifa_total : this.tarifa_tradicional).toFixed(2);
          }
          this.init = false;
        });
      }
    },
    tarifa(value) {
      if (value !== 0) {
        if (parseFloat(value.replace(/,\s*/, "")) >= this.cobertura) {
          this.Calcular();
          this.tarifa_vacia_error = false;
        } else {
          this.tarifa_vacia_error = true;
        }
      } else {
        this.tarifa_vacia_error = true;
      }
    },
    cantidad() {
      this.Calcular();
      if (this.cantidad <= this.cantidad_vehiculos) {
        this.cantidad_error = false;
      }
    },
    allTransacciones() {
      this.$store.state.Cargando = false;
    },
    subtotal() {
      this.total = parseFloat(this.subtotal).toFixed(2);
    },
    serviciosPerName() {
      this.totalPaginas = Math.ceil(this.serviciosPerName.length / this.elementosPorPagina);
    },
    nombre(value) {
      if (value !== "") {
        this.nombre_error = false;
      }
    },
    apellidos(value) {
      if (value !== "") {
        this.apellidos_error = false;
      }
    },
    email(value) {
      if (value !== "") {
        this.correo_error = false;
        this.email_error = false;
      }
    },
    telefono(value) {
      if (value !== "") {
        this.telefono_error = false;
        this.phone_error = false;
      }
    },
    edad(value) {
      if (value !== 0) {
        this.edad_error = false;
      }
    },
    sucursal(value) {
      if (value !== 0) {
        this.sucursal_error = false;
      }
    },
    tipo_pago(value) {
      if (value !== "") {
        this.tipo_pago_error = false;
      }
    },
    dias(value) {
      if (value <= 0) {
        this.error_fechas = true;
        this.fechaFin = null;
        this.dias = "";
      } else {
        this.error_fechas = false;
      }
    },
    password() {
      this.misearch = "";
    },

    items() {
      this.$store.state.Cargando = false;
    },
    cant() {
      this.loadDatas(true);
    },
    misearch() {
      this.loadDatas(true);
    }
  },
  methods: {
    Calcular() {
      if (this.tarifa !== 0 && this.dias !== 0 && this.cantidad !== 0) {
        this.total = (Number(parseFloat(this.tarifa.replace(/,\s*/, "")) * parseInt(this.dias) * parseInt(this.cantidad)) + Number(this.total_extras)).toFixed(2);
        this.tarifa_renta = (parseFloat(parseFloat(this.tarifa.replace(/,\s*/, "")) * this.dias) * parseInt(this.cantidad)).toFixed(2);
      }
    },
    Cancelar_Reserva(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "Esta acción no se puede deshacer",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        reverseButtons: true,
        showConfirmButton: false,
        cancelButtonText: "Cancelar",
        denyButtonText: "Si, cancelar la reserva!"
      }).then(async (result) => {
        if (result.isDenied) {
          const { data } = await this.$apollo.mutate({
            mutation: CANCELAR,
            variables: {
              id: this.DecodeId(id),
              agente: this.$store.state.userid
            }
          });
          if (data.cancelarReserva.success) {
            this.$apollo.mutate({
              mutation: REVISADA,
              variables: {
                id: this.DecodeId(id)
              }
            }).then(resp => {
              const Toast = Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                }
              });
              Toast.fire({
                icon: "error",
                title: "Reserva cancelada"
              });
              this.loadDatas(true);
            });
          }
        }
      });
    },
    Pagina_1() {
      this.pagina_uno = true;
      this.pagina_dos = false;
      this.pagina_tres = false;
    },
    Pagina_2() {
      var validacion = true;
      if (this.fechaRenta === null) {
        validacion = false;
        this.fechaRenta_error = true;
      }
      if (this.fechaFin === null) {
        validacion = false;
        this.fechaFin_error = true;
      }
      if (parseFloat(this.tarifa.replace(/,\s*/, "")) < this.cobertura) {
        this.tarifa_vacia_error = true;
        this.tarifa_error = true;
        return false;
      }
      if (validacion) {
        if (!this.showUpdate) {
          if (this.Difechas(Date.now(), this.fechaRenta) < 0) {
            this.fechaRenta_error = true;
            this.fechaRenta = null;
            this.error_fechas = true;
            return false;
          }
        }
        if (this.Difechas(this.fechaRenta, this.fechaFin) <= 0) {
          this.fechaRenta_error = true;
          this.fechaRenta = null;
          this.error_fechas = true;
          return false;
        }
        this.email_error = false;
        this.pagina_uno = false;
        this.pagina_dos = true;
        this.pagina_tres = false;

      } else {
        this.error = true;
      }
    },
    Pagina_3() {
      this.tarifa_error = false;
      this.error = false;
      this.pagina_uno = false;
      this.pagina_dos = false;
      this.pagina_tres = true;
    },
    eliminarComa(str) {
      const numero_convertido = str.toString();
      if (numero_convertido.includes(",")) {
        return parseFloat(numero_convertido.replace(/,\s*/, ""));
      } else {
        return parseFloat(numero_convertido);
      }
    },
    Guardar() {
      this.msg_create = false;
      this.$store.state.Cargando = true;
      var validacion = true;
      if (this.nombre === "") {
        validacion = false;
        this.nombre_error = true;
      }
      if (this.apellidos === "") {
        validacion = false;
        this.apellidos_error = true;
      }
      if (this.email === "") {
        validacion = false;
        this.correo_error = true;
      }
      if (this.telefono === "") {
        validacion = false;
        this.telefono_error = true;
      }
      if (this.edad === 0 || this.edad < 21) {
        validacion = false;
        this.edad_error = true;
      }
      if (this.sucursal === 0) {
        validacion = false;
        this.sucursal_error = true;
      }
      if (this.tarifa === 0) {
        validacion = false;
        this.tarifa_vacia_error = true;
      }
      if (this.tipo_pago === "") {
        validacion = false;
        this.tipo_pago_error = true;
      }
      if (this.categoria === 0) {
        validacion = false;
        this.categoria_error = true;
      }
      if (this.cantidad === 0) {
        validacion = false;
        this.cantidad_error = true;
      }
      if (/^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/.test(this.email) === false) {
        this.correo_error = true;
        this.email_error = true;
        validacion = false;
      }
      if (validacion) {
        this.$apollo.mutate({
          mutation: LINK,
          variables: {
            apellidos: this.apellidos,
            categoria: parseInt(this.categoria),
            email: this.email,
            nombre: this.nombre,
            sucursal: parseInt(this.sucursal),
            telefono: this.telefono,
            total: this.eliminarComa(this.total_pagado),
            comentarios: this.comentarios,
            dias: this.dias,
            edad: this.edad,
            fechaFin: this.fechaFin,
            fechaRenta: this.fechaRenta,
            cantidad: this.cantidad,
            tarifa: this.eliminarComa(this.tarifa),
            serviciosExtras: this.eliminarComa(this.total_extras),
            tipo: this.tipo,
            user: this.$store.state.userid,
            metodo_pago: this.tipo_pago,
            origen: "Administración",
            servicios_reservados: this.servicios_reservados,
            cotizacion: this.cotizacion
          }
        }).then(response => {
          if (response.data.link.success) {
            if (!this.extra_service_add) {
              createExtraServiceToReserve({
                daily: this.diario_otro,
                description: this.description_extra_service,
                price: this.precio_otro,
                reserve: response.data.link.reserva
              }).then(resp => {
                if (!resp.data.data.createServiceToReserve.success) {
                  console.log(resp.data.data.createServiceToReserve.error);
                }
              });
            }
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Reserva creada correctamente"
            });
            this.$store.state.Cargando = false;
            this.closeModal();
          } else {
            this.$store.state.Cargando = false;
            this.msg_create = true;
            this.msg_create_text = response.data.link.error;
          }
        });
      } else {
        this.$store.state.Cargando = false;
        this.error = true;
      }
    },
    Actualizar() {
      this.$store.state.Cargando = true;
      var validacion = true;
      if (this.tarifa === 0) {
        validacion = false;
        this.tarifa_vacia_error = true;
      }
      if (this.tipo_pago === "") {
        validacion = false;
        this.tipo_pago_error = true;
      }
      if (this.categoria === 0) {
        validacion = false;
        this.categoria_error = true;
      }
      if (this.cantidad === 0) {
        validacion = false;
        this.cantidad_error = true;
      }
      if (validacion) {
        this.$apollo.mutate({
          mutation: UPDATE,
          variables: {
            id: this.id,
            apellidos: this.apellidos,
            categoria: parseInt(this.categoria),
            email: this.email,
            nombre: this.nombre,
            sucursal: parseInt(this.sucursal),
            telefono: this.telefono,
            total: this.eliminarComa(this.total_pagado),
            comentarios: this.comentarios,
            dias: this.dias,
            edad: this.edad,
            fechaFin: this.fechaFin,
            fechaRenta: this.fechaRenta,
            cantidad: this.cantidad,
            tarifa: this.eliminarComa(this.tarifa),
            serviciosExtras: this.eliminarComa(this.total_extras),
            tipo: this.tipo,
            metodo_pago: this.tipo_pago,
            user: this.$store.state.userid,
            servicios_reservados: this.servicios_reservados
          }
        }).then(response => {
          if (response.data.updateReserva.success) {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "success",
              title: "Reserva actualizada correctamente"
            });
            this.$store.state.Cargando = false;
            this.closeUpdate();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: response.data.updateReserva.error
            });
            this.$store.state.Cargando = false;
            this.closeUpdate();
          }
        });
      } else {
        this.$store.state.Cargando = false;
        this.error = true;
      }
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    },
    openDetalles(item) {
      this.item = item;
      this.$store.state.reserva = null;
      this.$store.state.reserva_notification = false;
      this.monto_total = parseFloat(parseFloat(item.serviciosExtras) * parseInt(item.cantidad)) + (parseFloat(item.tarifaDiaria) * parseInt(item.dias) * parseInt(item.cantidad));
      this.total_renta = parseFloat(item.tarifaDiaria) * parseInt(item.dias) * parseInt(item.cantidad);
      this.$apollo.query({
        query: SERVICIOS_BY_RESERVE,
        variables: {
          id: this.DecodeId(item.id)
        }
      }).then(response => {
        this.services_ex = response.data.servicesByReservation;
      });
      this.showDetalles = true;
      document.body.classList.add("modal-open");
      this.$apollo.mutate({
        mutation: REVISADA,
        variables: {
          id: this.DecodeId(item.id)
        }
      }).then(resp => {
        this.loadDatas(true);
      });
    },
    generateReport(item) {
      getReservationsById(decodeBase64(item.id)).then(resp => {
        this.transaccion = resp.data.data.reservaById;
        console.log(this.transaccion);
        this.exportar_pdf = true;
        this.$store.state.Cargando = true;
        this.monto_total = parseFloat(parseFloat(this.transaccion.serviciosExtras) * parseInt(this.transaccion.cantidad)) + (parseFloat(this.transaccion.tarifaDiaria) * parseInt(this.transaccion.dias) * parseInt(this.transaccion.cantidad));
        if (this.transaccion.tipoCobertura === "total") {
          CoberturasPerTarifaTotal().then(response => {
            this.coberturas = response.data.data.coberturasPerTarifaTotal;
            setTimeout(() => {
              const contentToExport = this.$refs.contrato_plantilla;
              const options = {
                margin: [5, 5, 5, 5],
                filename: "Reserva.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "mm", format: "letter", orientation: "portrait" }
              };
              html2pdf()
                .from(contentToExport)
                .set(options)
                .output("blob", "reserva.pdf")
                .then(dataUri => {
                  const blob = new Blob([dataUri], { type: "application/pdf" });
                  const url = URL.createObjectURL(blob);
                  window.open(url, "_blank");
                });
              this.closeExportarPDF();
            }, 50);
          });
        } else {
          CoberturasPerTarifaTradicional().then(response => {
            this.coberturas = response.data.coberturasPerTarifaTradicional;
            setTimeout(() => {
              const contentToExport = this.$refs.contrato_plantilla;
              const options = {
                margin: [5, 5, 5, 5],
                filename: "Reserva.pdf",
                image: { type: "jpeg", quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: "mm", format: "letter", orientation: "portrait" }
              };
              html2pdf()
                .from(contentToExport)
                .set(options)
                .output("blob", "reserva.pdf")
                .then(dataUri => {
                  const blob = new Blob([dataUri], { type: "application/pdf" });
                  const url = URL.createObjectURL(blob);
                  window.open(url, "_blank");
                });
              this.closeExportarPDF();
            }, 50);
          });
        }
      });
    },
    closeExportarPDF() {
      this.$store.state.Cargando = false;
      this.exportar_pdf = false;
      this.transaccion = null;
    },
    openModal() {
      this.cantidad = 1;
      this.showModal = true;
      this.deshabilitado = false;
      this.error = false;
      this.error_seguridad = false;
      this.error_fechas = false;
      document.body.classList.add("modal-open");
    },
    openSeguridad() {
      this.seguridad = true;
      this.deshabilitado = false;
      document.body.classList.add("modal-open");
    },
    closeModal() {
      this.description_extra_service = "";
      this.extra_service_add = true;
      this.precio_otro = 0;
      this.description_extra_service = "";
      this.diario_otro = false;
      this.paginaActual = 1;
      this.$store.state.Cargando = false;
      this.servicios_reservados = [];
      this.services = [];
      this.total = 0;
      this.tipo_pago = "";
      this.subtotal = 0;
      this.apellidos = "";
      this.categoria = 0;
      this.email = "";
      this.nombre = "";
      this.phone_error = false;
      this.telefono = "";
      this.comentarios = "";
      this.dias = 0;
      this.edad = 0;
      this.fechaFin = null;
      this.fechaRenta = null;
      this.showModal = null;
      this.error = false;
      this.pagina_uno = true;
      this.pagina_dos = false;
      this.pagina_tres = false;
      this.tarifa = 0;
      this.tarifa_total = 0;
      this.total_extras = 0;
      this.total_extra_info = 0;
      this.tarifa_tradicional = 0;
      this.deshabilitado = false;
      this.total_pagado = 0;
      this.deshabilitar_categoria = false;
      this.loadDatas();
      document.body.classList.add("modal-open");
    },
    isBase64(str) {
      return /[a-zA-Z]/.test(str);
    },
    DecodeId(id) {
      if (this.isBase64(id)) {
        return decodeURIComponent(escape(window.atob(id))).split(":")[1];
      } else {
        return id;
      }

    },
    openUpdate(id) {
      this.init = true;
      this.cantidad = 1;
      this.$apollo.query({
        query: RESERVA_BY_ID,
        variables: {
          id: id
        },
        fetchPolicy: "network-only"
      }).then(response => {
        this.$apollo.mutate({
          mutation: REVISADA,
          variables: {
            id: id
          }
        }).then(resp => {
        });
        const item = response.data.reservaById;
        this.servicios_reservados = [];
        this.id = item.id;
        this.total = item.total;
        this.deshabilitado = false;
        this.apellidos = item.apellidos;
        this.categoria = item.categoria.id;
        this.categoria_id = item.categoria.id;
        this.categoria_name = item.categoria.name;
        this.email = item.email;
        this.nombre = item.nombre;
        this.sucursal = item.sucursal.id;
        this.telefono = item.telefono;
        this.comentarios = item.comentarios;
        this.dias = item.dias;
        this.edad = item.edad;
        this.fechaFin = new Date(item.fechaFin);
        this.fechaRenta = new Date(item.fechaRenta);
        this.cantidad = item.cantidad;
        this.tipo = item.tipoCobertura;
        this.tipo_pago = item.metodoPago;

        this.showUpdate = true;
        this.error = false;
        this.phone_error = false;
        document.body.classList.add("modal-open");

        if (this.categoria !== null) {
          this.$apollo.query({
            query: CANTIDAD,
            variables: {
              fechaRenta: this.fechaRenta,
              fechaRegreso: this.fechaFin,
              sucursal: this.sucursal,
              categoria: this.categoria
            }
          }).then(response => {
            this.cantidad_vehiculos = response.data.vehiculosDisponiblesCategoria;
          });
          this.$apollo.query({
            query: CATEGORIA_ID,
            variables: {
              id: this.categoria
            }
          }).then(resp => {
            const categoria = resp.data.categoriaPerId;
            this.tarifa_tradicional = this.temporada ? categoria.tradicionalAlta : categoria.tradicionalBaja;
            this.tarifa_total = this.temporada ? categoria.totalAlta : categoria.totalBaja;
          });
        }

        this.serviciosPerName.forEach(item_for => {
          this.$apollo.query({
            query: RESERVA_SERVICIO,
            variables: {
              idReserva: item.id,
              idServicio: item_for ? item_for.id : 0
            },
            fetchPolicy: "network-only"
          }).then(response => {
            this.services[item_for.id] = response.data.servicioPorReserva;
            if (response.data.servicioPorReserva) {
              this.Adicionar(item_for.id, item_for.precio, item_for.diario);
            }
          });
        });
        if (item.extraservicereserveSet.length > 0) {
          this.description_extra_service = item.extraservicereserveSet[0].description;
          this.precio_otro = item.extraservicereserveSet[0].price;
          this.diario_otro = item.extraservicereserveSet[0].daily;
          this.Adicionar("otro", this.precio_otro, this.diario_otro);
        }
        this.tarifa = item.tarifaDiaria;
        this.total_pagado = item.total;
      });
    },
    closeUpdate() {
      this.description_extra_service = "";
      this.extra_service_add = true;
      this.precio_otro = 0;
      this.description_extra_service = "";
      this.diario_otro = false;
      this.paginaActual = 1;
      if (this.$store.state.update_reserva === "entrega_autos") {
        this.$store.state.Cargando = false;
        this.$store.state.update_reserva = "falso";
        this.$store.state.reserva = null;
        this.$router.push({ name: "Entrega de autos" });
        return false;
      }
      if (this.$store.state.reserva_cotizacion) {
        this.$store.state.reserva_cotizacion = false;
        this.$router.push({ name: "Cotizaciones" });
        return false;
      }
      if (this.$store.state.reserva_disponibles) {
        this.$store.state.reserva_disponibles = false;
        this.$router.push({ name: "Disponibles" });
        return false;
      }
      this.$store.state.update_reserva = "falso";
      this.$store.state.reserva_cotizacion = false;
      this.$store.state.reserva_disponibles = false;
      this.loadDatas(true);
      this.servicios_reservados = [];
      this.services = [];
      this.total = 0;
      this.tipo_pago = "";
      this.subtotal = 0;
      this.apellidos = "";
      this.categoria = 0;
      this.email = "";
      this.nombre = "";
      this.phone_error = false;
      this.telefono = "";
      this.comentarios = "";
      this.dias = 0;
      this.edad = 0;
      this.fechaFin = null;
      this.fechaRenta = null;
      this.showUpdate = false;
      this.error = false;
      this.pagina_uno = true;
      this.pagina_dos = false;
      this.pagina_tres = false;
      this.tarifa = 0;
      this.tarifa_total = 0;
      this.total_extras = 0;
      this.total_extra_info = 0;
      this.total_pagado = 0;
      this.tarifa_tradicional = 0;
      this.deshabilitado = false;
      this.services = [];
      document.body.classList.add("modal-open");
    },
    closeDetalles() {
      this.item = null;
      this.$store.state.reserva = null;
      this.$store.state.reserva_notification = false;
      this.showDetalles = false;
      this.deshabilitado = false;
      document.body.classList.remove("modal-open");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    Contrato(item) {
      this.$store.state.anterior = "Rentas_Admin";
      this.$store.state.reserva = item;
      this.$router.push("/contratos/nuevo");
    },
    autocomplete() {
      this.autocompleteItems = this.allCliente.filter(item =>
        item.nombre.toLowerCase().includes(this.nombre.toLowerCase())
      );
      this.showSubmenu = this.nombre !== "";
    },
    selectItem(item) {
      this.deshabilitado = true;
      this.nombre = item.nombre;
      this.apellidos = item.apellidos;
      this.edad = item.edad;
      this.email = item.email;
      this.telefono = item.telefono;
      this.showSubmenu = false;
    },
    Clear_Data() {
      this.deshabilitado = false;
      this.nombre = "";
      this.apellidos = "";
      this.edad = "";
      this.email = "";
      this.telefono = "";
      this.showSubmenu = false;
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    Adicionar(id, precio, diario) {
      if (diario) {
        this.total_extras = parseFloat(parseFloat(this.total_extras) + (parseFloat(precio) * this.dias)).toFixed(2);
        this.total_extra_info = parseFloat(this.total_extras).toFixed(2);
        this.Calcular();
      } else {
        this.total_extras = parseFloat(parseFloat(this.total_extras) + (parseFloat(precio))).toFixed(2);
        this.total_extra_info = parseFloat(this.total_extras).toFixed(2);
        this.Calcular();
      }
      if (id === "otro") {
        this.extra_service_add = false;
      } else if (!this.servicios_reservados.includes(id)) {
        this.servicios_reservados.push(id);
      }
    },
    Quitar(id, precio, diario) {
      if (diario) {
        this.total_extras = parseFloat(parseFloat(this.total_extras) - (parseFloat(precio) * this.dias)).toFixed(2);
        this.total_extra_info = parseFloat(this.total_extras).toFixed(2);
        this.Calcular();
      } else {
        this.total_extras = parseFloat(parseFloat(this.total_extras) - (parseFloat(precio))).toFixed(2);
        this.total_extra_info = parseFloat(this.total_extras).toFixed(2);
        this.Calcular();
      }
      const index = this.servicios_reservados.indexOf(id);
      if (id === "otro") {
        this.extra_service_add = true;
      } else if (index > -1) {
        this.servicios_reservados.splice(index, 1);
      }
    },
    getURL(url) {
      if (url !== "") {
        return this.$store.state.store_url + url;
      } else {
        return noImagen;
      }
    },
    paginaAnterior() {
      if (this.paginaActual > 1) {
        this.paginaActual--;
      }
    },
    irPaginaActual(index) {
      this.paginaActual = index;
    },
    paginaSiguiente() {
      if (this.paginaActual < this.totalPaginas) {
        this.paginaActual++;
      }
    },
    closeSeguridad() {
      if (this.$store.state.update_reserva === "entrega_autos") {
        this.$store.state.Cargando = false;
        this.$store.state.update_reserva = "falso";
        this.$store.state.reserva = null;
        this.$router.push({ name: "Entrega de autos" });
        return false;
      }
      if (this.$store.state.reserva_cotizacion) {
        this.$store.state.reserva_cotizacion = false;
        this.$router.push({ name: "Cotizaciones" });
        return false;
      }
      if (this.$store.state.reserva_disponibles) {
        this.$store.state.reserva_disponibles = false;
        this.$router.push({ name: "Disponibles" });
        return false;
      }
      this.seguridad = false;
      this.password = "";
    },
    Comprobar() {
      this.$store.state.Cargando = true;
      if (this.password === "") {
        this.error = true;
        this.$store.state.Cargando = false;
      } else {
        this.$apollo.mutate({
          mutation: SEGURIDAD,
          variables: {
            email: this.$store.state.email,
            password: this.password
          }
        }).then(response => {
          if (response.data.login.success) {
            this.$store.state.Cargando = false;
            this.$store.state.update_reserva = "falso";
            this.$store.state.reserva_cotizacion = false;
            this.$store.state.reserva_disponibles = false;
            this.closeSeguridad();
            this.openModal();
          } else {
            this.error_seguridad = true;
            this.$store.state.Cargando = false;
          }
        });
      }
    },
    Temporada_alta() {
      if (this.fechaRenta !== null && this.fechaFin !== null) {
        this.$apollo.query({
          query: TEMPORADA,
          variables: {
            fechaInicio: this.fechaRenta.toISOString().slice(0, 10),
            fechaFin: this.fechaFin.toISOString().slice(0, 10)
          }
        }).then(response => {
          this.Alta = response.data.temporadaAlta;
        });
      }
    },
    getClassByPaymentStatus(estado) {
      if (estado === "No pagado") {
        return "bg-danger";
      } else if (estado === "Pago total") {
        return "bg-success";
      } else if (estado === "Pago parcial") {
        return "bg-warning";
      } else {
        return "";
      }
    },

    loadDatas(init = false) {
      this.$store.state.Cargando = true;
      let next = "";
      let before = "";
      if (init === false) {
        before = this.lastEndCursor;
        next = this.lastStartCursor;
      }
      getReservations({
        name: this.misearch,
        before: before,
        after: next,
        first: this.cant,
        origen: "Administración"
      }).then(resp => {
        if (init === true) {
          this.hasNextPage = resp.data.data.searchReservations.pageInfo.hasNextPage;
          this.hasPreviousPage = resp.data.data.searchReservations.pageInfo.hasPreviousPage;
        }
        this.startCursor = resp.data.data.searchReservations.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchReservations.pageInfo.endCursor;
        this.items = resp.data.data.searchReservations.edges.map(value => {
          return value.node;
        });
      });
    },
    nextPage() {
      this.$store.state.Cargando = true;
      getReservationsNext({
        name: this.misearch,
        after: this.endCursor,
        first: this.cant,
        origen: "Administración"
      }).then(resp => {
        this.lastEndCursor = "";
        this.lastStartCursor = this.endCursor;
        this.hasNextPage = resp.data.data.searchReservations.pageInfo.hasNextPage;
        this.hasPreviousPage = true;
        this.startCursor = resp.data.data.searchReservations.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchReservations.pageInfo.endCursor;
        this.items = resp.data.data.searchReservations.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        alert(err);
      }).finally(() => {
        this.$store.state.Cargando = false;
      });

    },
    prevPage() {
      this.$store.state.Cargando = true;
      getReservationsPrev({
        name: this.misearch,
        before: this.startCursor,
        first: this.cant,
        origen: "Administración"
      }).then(resp => {
        this.lastEndCursor = this.startCursor;
        this.lastStartCursor = "";
        this.hasNextPage = true;
        this.hasPreviousPage = resp.data.data.searchReservations.pageInfo.hasPreviousPage;
        this.startCursor = resp.data.data.searchReservations.pageInfo.startCursor;
        this.endCursor = resp.data.data.searchReservations.pageInfo.endCursor;
        this.items = resp.data.data.searchReservations.edges.map(value => {
          return value.node;
        });
      }).catch((err) => {
        this.toast.fire({
          icon: "error",
          title: err.message
        });
      }).finally(() => {
        this.$store.state.Cargando = false;
      });

    }
  },
  created() {
    this.loadDatas(true);
    this.$store.state.Cargando = true;
    this.sucursal = this.$store.state.userSucursal ? this.$store.state.userSucursal : 0;
    if (this.$store.state.reserva_notification) {
      this.openDetalles(this.$store.state.reserva);
      this.$store.state.reserva = null;
      this.$store.state.reserva_notification = false;
    } else if (this.$store.state.reserva_disponibles) {
      this.fechaRenta = this.$store.state.salida;
      this.fechaFin = this.$store.state.regreso;
      this.categoria = this.$store.state.categoria ? this.$store.state.categoria.id : null;
      if (this.categoria !== 0) {
        this.deshabilitar_categoria = true;
      }
      this.openSeguridad();
    } else if (this.$store.state.reserva_cotizacion) {
      this.fechaRenta = new Date(this.$store.state.cotizacion.fechaRenta);
      this.fechaFin = new Date(this.$store.state.cotizacion.fechaFin);
      this.categoria = this.$store.state.cotizacion ? this.$store.state.cotizacion.categoria.id : null;
      this.total_extras = this.$store.state.cotizacion.serviciosExtras;
      this.tarifa = this.$store.state.cotizacion.tarifaDiaria;
      this.total = this.$store.state.cotizacion.total;
      this.services = this.$store.state.services;
      this.cantidad = this.$store.state.cotizacion.cantidad;
      this.cotizacion = this.$store.state.cotizacion.id;
      if (this.cantidad > this.cantidad_vehiculos) {
        this.cantidad_error = true;
      }
      this.$apollo.query({
        query: CLIENTE_EMAIL,
        variables: {
          email: this.$store.state.cotizacion.email
        }
      }).then(res => {
        if (res.data) {
          this.selectItem(res.data.clientePerEmail);
        } else {
          this.nombre = this.$store.state.cotizacion.nombre;
          this.apellidos = this.$store.state.cotizacion.apellidos;
          this.edad = this.$store.state.cotizacion.edad;
          this.email = this.$store.state.cotizacion.email;
          this.phone = this.$store.state.cotizacion.telefono;
        }
      });
      this.openSeguridad();
    } else if (this.$store.state.update_reserva === "entrega_autos") {
      this.openUpdate(this.$store.state.reserva.id);
    }
  },
  computed: {
    ...mapState(["temporada"]),
    ...mapGetters(["tienePermiso"]),
    elementosPaginaActual() {
      const inicio = (this.paginaActual - 1) * this.elementosPorPagina;
      const fin = inicio + this.elementosPorPagina;
      return this.serviciosPerName.slice(inicio, fin);
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal-content-contrato {
  background-color: white;
  padding: 20px;
  margin-top: 999999px;
  border-radius: 15px;
  width: 90%;
}

.input-icon:focus {
  background-image: url('../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.input-container {
  position: relative;
}

.clear-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 60%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

.btn-primary1 {
  background-color: #FFA633;
  border: 2px solid #FFA633 !important;
  box-shadow: inset 0 0 0 0 #fff;
  color: #fff;
}

.btn-primary1:hover {
  color: rgba(255, 255, 255, 0.8);
}

.btn-issy {
  background-color: #fff;
  border: 2px solid #FFA633 !important;
  color: #FFA633;
  box-shadow: inset 0 0 0 0 #fff;
}

.btn-issy:hover {
  color: rgba(255, 166, 51, 0.87);
}

.modal-content1 {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 30%;
}

.services_extras_container {
  height: 150px;
  overflow-y: hidden;
}

.services_extras {
  height: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 550px) {
  .modal-content1 {
    width: 80%;

  }

  .modal-content {
    width: 100%;
    margin-top: 200px;
  }

  .services_extras_container {
    height: 300px;
    overflow-y: hidden;
  }

  .services_extras {
    height: 30%;
    display: block;
    justify-content: center;
    align-items: center;
  }
}

.linea {
  padding-bottom: 10px;
  padding-top: 5px;
  border-bottom: 2px solid #000;
}

.text-adicional {
  font-style: italic;
  line-height: 18px;
  text-transform: uppercase;
  font-size: 16px;
}

div p {
  font-size: 14px;
  line-height: 16px;
  color: #000;
}

div li {
  font-size: 14px;
  line-height: 16px;
  color: #000;
}
</style>

<template xmlns="http://www.w3.org/1999/html">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!-- Card header -->
          <div class="card-header bg-gradient-warning">
            <h5 class="mb-0 text-white">
              Lista de vehículos contratados
            </h5>
            <p class="mb-0 text-sm">
            </p>
          </div>
          <div class="row m-3">
            <div class="col-lg-3 col-sm-12 mb-2">
              Fecha de salida
              <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                             placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm a"
                             minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                             v-model="salida" class="border-secondary border">
                <template #minutes="{ text, value }">
                  00
                </template>
              </VueDatePicker>
            </div>
            <div class="col-lg-3 col-sm-12 mb-2">
              Fecha de regreso
              <VueDatePicker cancelText="Cancelar" selectText="Aceptar" locale="es"
                             placeholder="Seleccione una fecha" format="dd/MM/yyyy hh:mm a"
                             minutes-increment="60" no-minutes-overlay :start-time="{minutes: 0}"
                             v-model="regreso" class="border-secondary border">
                <template #minutes="{ text, value }">
                  00
                </template>
              </VueDatePicker>
            </div>
            <div class="col-lg-3 col-sm-12 mb-3">
              Categoría
              <select v-model="categoria_id" class="form-control p-2 border-secondary border">
                <option value="0">Todas</option>
                <option v-for="item in allCategoriasAdmin" :key="item.id" :value="item.id" v-show="item.isActive">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="col-lg-3 col-sm-12 mb-2 d-flex flex-column justify-content-end">
              <button @click="Buscar" class="btn btn-dark w-100 mt-4" style="height: 42px" type="submit">
                <i class="fa fa-search me-1"></i>
                Buscar
              </button>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-striped" id="miTabla">
              <thead class="thead-light">
              <tr class="text-center align-middle">
                <th>Fecha</th>
                <th>No. de folio</th>
                <th>Cliente</th>
                <th>Categoría</th>
                <th>No. económico</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Estatus</th>
                <th>Acciones</th>
              </tr>
              </thead>
              <tbody>
              <tr class="text-center align-middle" v-for="item in vehiculosContratados" :key="item.id">
                <td class="text-sm font-weight-normal">
                  {{ item.fecha }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.numContrato }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.cliente.nombre }} {{ item.cliente.apellidos }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.vehiculo.categoria.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.vehiculo.noEcon }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.vehiculo.version.modelo.marca.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.vehiculo.version.modelo.name }}
                </td>
                <td class="text-sm font-weight-normal">
                  {{ item.estado }}
                </td>
                <td>
                  <div class="btn-group pt-3" role="group">
                    <button data-bs-toggle="tooltip"
                            data-bs-placement="top" @click="openCar(item)"
                            title="Detalles del contrato" data-container="body" data-animation="true"
                            class="btn btn-success p-1 ms-1">
                      <i class="material-icons opacity-10">visibility</i></button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divModal">
    <div v-if="showCar" :class="['modal', { 'show': showCar }]" @transitionend="onTransitionEnd">
      <div class="modal-content">
        <div class="h-100">
          <div class="row mb-3 border-bottom border-dark">
            <h4 class="text-start"><i class="fa fa-bars me-2"></i>Detalles del contrato<i @click="closeCar"
                                                                                          class="material-icons-round opacity-10 modal-icon">close</i>
            </h4>
          </div>
          <div class="p-3 card-body">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>No. Folio:</strong>
                <p>{{ item.numContrato }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de Contrato:</strong>
                <p>{{ formatearFecha(item.fecha) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Estado del contrato:</strong>
                <p>{{ item.estado }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de salida:</strong>
                <p>{{ formatearFecha(item.fechaSalida) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Fecha de regreso:</strong>
                <p>{{ formatearFecha(item.fechaRegreso) }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Días reservados:</strong>
                <p>{{ item.dias }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Categoría:</strong>
                <p>{{ item.vehiculo.categoria.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Marca:</strong>
                <p>{{ item.vehiculo.version.modelo.marca.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Modelo:</strong>
                <p>{{ item.vehiculo.version.modelo.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Sucursal:</strong>
                <p>{{ item.sucursal.name }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Canal:</strong>
                <p>{{ item.canal === "portal" ? "Portal" : "Administración" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Agente:</strong>
                <p>{{ item.agente.firstName }} {{ item.agente.lastName }}</p>
              </div>
              <h5 class="text-bold">Tarifas</h5>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Tarifa diaria:</strong>
                <p>$ {{ numeroFormateado(item.tarifaDiaria) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Tarifa de horas extras</strong>
                <p>$ {{ numeroFormateado(item.precioHoraExtra) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Servicios extras:</strong>
                <p>$ {{ numeroFormateado(item.tarifaExtra) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Total:</strong>
                <p>$ {{ numeroFormateado(item.total) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4">
                <strong>Método de pago:</strong>
                <p>{{ item.tipoPago === "tarjeta" ? "Tarjeta" : "Efectivo" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='tarjeta'">
                <strong>Check in:</strong>
                <p>$ {{ numeroFormateado(item.checkin) }} MXN</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='tarjeta'">
                <strong>No. tarjeta:</strong>
                <p>{{ item.tarjeta ? item.tarjeta.numero : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='tarjeta'">
                <strong>No. autorización:</strong>
                <p>{{ item.tarjeta ? item.tarjeta.autorizacion : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='tarjeta'">
                <strong>Fecha de vencimiento:</strong>
                <p>{{ item.tarjeta ? formatearFecha(item.tarjeta.vencimiento) : "" }}</p>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4" v-show="item.tipoPago==='efectivo'">
                <strong>Depósito en efectivo:</strong>
                <p>$ {{ numeroFormateado(item.depositoEfectivo) }} MXN</p>
              </div>
            </div>
            <div class="col-6 d-flex justify-content-end">
              <button @click="closeCar" class="btn btn-dark" type="button">Cerrar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";
import MaterialButton from "@/components/MaterialButton.vue";
import Swal from "sweetalert2";
import "datatables.net";
import VueDatePicker from "@vuepic/vue-datepicker";
import axios from "axios";
import moment from "moment";
import { ref } from "vue";
import { mapState } from "vuex";

const BUSCAR = gql`
          query VehiculosPerStatus($status:Int!) {
    vehiculosPerStatus(status: $status) {
        id
        noEcon
        anno
        transmision
        vin
        imagen
        fechaCredito
        fechaCirculacion
        fechaPoliza
        placa
        estado{
        name
        }
        minimo
        sucursal{
        name
        }
        estatus
        isActive
        numeroSerie
        color
        capacidadTanke
        fechaAlta
        aseguradora {
            name
        }
        titular {
            name
        }
        categoria {
            id
            name
            }
        version{
          id
          name
          modelo{
            id
            name
            marca{
              id
              name
              }
          }
        }
      }
    }`;
const ALLSUCURSALES = gql`
query AllSucursales {
    allSucursales {
        id
        name
        isActive
    }
}
`;
const CATEGORIAS = gql`query CategoriasDisponiblesAdmin($sucursal:Int!, $fechaSalida:DateTime!, $fechaRegreso:DateTime!) {
    categoriasDisponiblesAdmin(
        fechaSalida: $fechaSalida
        fechaRegreso: $fechaRegreso
    ) {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}`;
const CONTRATADOS = gql`
query VehiculosContratados($salida:DateTime!, $regreso:DateTime!, $sucursal:Int!, $categoria:Int!) {
    vehiculosContratados(fechaSalida: $salida, fechaRegreso: $regreso, sucursal: $sucursal, categoria:$categoria) {
        id
        estado
        tipoPago
        fecha
        precioHoraExtra
        numContrato
        fechaSalida
        fechaRegreso
        tarifaDiaria
        tarifaExtra
        dias
        tanque
        deuda
        total
        depositoEfectivo
        checkin
        credito
        comentarios
        canal
        agente {
            id
            username
            firstName
            lastName
            email
        }
        cliente {
            id
            nombre
            apellidos
            telefono
            email
        }
        vehiculo {
            id
            version{
              name
              modelo{
                name
                marca{
                  name
                }
              }
            }
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
            }
        }
        sucursal {
            id
            name
        }
        conductor {
            id
            nombre
            apellidos
            edad
            cliente {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
            }
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
 }`;
const ALL_CONTRATADOS = gql`
query ContratoPerName {
    contratoPerName(name: "", estado:"Abierto") {
        id
        estado
        tipoPago
        fecha
        precioHoraExtra
        numContrato
        fechaSalida
        fechaRegreso
        tarifaDiaria
        tarifaExtra
        dias
        tanque
        deuda
        total
        depositoEfectivo
        checkin
        credito
        comentarios
        canal
        agente {
            id
            username
            firstName
            lastName
            email
        }
        cliente {
            id
            nombre
            apellidos
            telefono
            email
        }
        vehiculo {
            id
            noEcon
            version{
              name
              modelo{
                name
                marca{
                  name
                }
              }
            }
            categoria {
                id
                name
                tradicionalAlta
                totalAlta
                tradicionalBaja
                totalBaja
            }
        }
        sucursal {
            id
            name
        }
        conductor {
            id
            nombre
            apellidos
            edad
            cliente {
                id
                nombre
                apellidos
                telefono
                email
                edad
                isActive
                direccion
                postal
                telefonoEmergencia
            }
        }
        tarjeta {
            id
            numero
            vencimiento
            autorizacion
        }
    }
 }`;
const ALLCATEGORIAS = gql`
query AllCategorias {
    allCategoriasAdmin {
        id
        name
        tradicionalAlta
        totalAlta
        tradicionalBaja
        totalBaja
        isActive
        fecha
        image
        iconoWhite
        iconoBlack
        maletas
        maletasSm
        personas
        transmision
        lugar
    }
}`;
export default {
  name: "List_Categorias",
  components: { MaterialButton, VueDatePicker },
  data() {
    return {
      misearch: "",
      vehiculosContratados: [],
      allSucursales: [],
      allCategoriasAdmin: [],
      showModal: false,
      showFechas: false,
      showCar: false,
      error: false,
      fechaCirculacion: null,
      fechaCredito: null,
      fechaPoliza: null,
      estatus: "",
      status: 0,
      carid: 0,
      preview: null,
      imagen: null,
      car: null,
      sucursal: null,
      ImageModal: false,
      ImageUpdateModal: false,
      categoria_id: 0,
      salida: null,
      regreso: null,
      item: null
    };
  },
  watch: {
    vehiculosContratados() {
      this.$store.state.Cargando = false;
    }
  },
  apollo: {
    allSucursales: {
      query: ALLSUCURSALES,
      fetchPolicy: "cache-and-network"
    },
    allCategoriasAdmin: {
      query: ALLCATEGORIAS,
      fetchPolicy: "cache-and-network"
    }
  },
  methods: {
    Buscar() {
      this.$store.state.Cargando = true;
      if (this.Difechas(this.salida, this.regreso) <= 0) {
        this.regreso = null;
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "La fecha de regreso no puede ser anterior a la fecha de salida"
        });
        this.$store.state.Cargando = false;
        return false;
      }
      if (this.sucursal !== null && this.salida !== null && this.regreso !== null) {
        this.$apollo.query({
          query: CONTRATADOS,
          variables: {
            salida: this.salida,
            regreso: this.regreso,
            sucursal: this.sucursal,
            categoria: this.categoria_id
          }
        }).then(response => {
          this.vehiculosContratados = response.data.vehiculosContratados;
          if (this.vehiculosContratados.length === 0) {
            this.$store.state.Cargando = false;
            const Toast = Swal.mixin({
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              }
            });
            Toast.fire({
              icon: "error",
              title: "No existen registros"
            });
          }
        });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: "error",
          title: "Debe llenar todos los campos"
        });
        this.$store.state.Cargando = false;
      }
    },
    openModal(id) {
      this.carid = id;
      this.showModal = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    openFechas(fechaCredito, fechaPoliza, fechaCirculacion, id) {
      this.fechaCredito = fechaCredito;
      this.fechaPoliza = fechaPoliza;
      this.fechaCirculacion = fechaCirculacion;
      this.carid = id;
      this.showFechas = true;
      this.error = false;
      document.body.classList.add("modal-open");
    },
    async openCar(car) {
      this.item = car;
      this.showCar = true;
      document.body.classList.add("modal-open");
    },
    closeCar() {
      this.showCar = false;
      document.body.classList.remove("modal-open");
    },
    closeFechas() {
      this.showFechas = false;
      this.fechaCredito = null;
      this.fechaPoliza = null;
      this.fechaCirculacion = null;
      document.body.classList.remove("modal-open");
    },
    closeModal() {
      this.carid = 0;
      this.estatus = "";
      this.showModal = false;
      this.error = false;
      document.body.classList.remove("modal-open");
    },
    Difechas(fecha1, fecha2) {
      return moment(fecha2).diff(moment(fecha1), "hours");
    },
    onTransitionEnd(event) {
      if (event.propertyName === "opacity" && !this.showModal) {
        this.$emit("close");
      }
    },
    onImagenSeleccionada(event) {
      this.imagen = event.target.files[0];
      this.preview = URL.createObjectURL(this.imagen);
    },
    Vencido(fechaCredito, fechaPoliza, fechaCirculacion) {
      return fechaCirculacion <= moment().format("YYYY-MM-DD") ||
        fechaPoliza <= moment().format("YYYY-MM-DD") ||
        fechaCredito <= moment().format("YYYY-MM-DD");
    },
    Contrato(item) {
      this.$store.state.vehiculo = item;
      this.$router.push("/contratos/nuevo");
    },
    formatearFecha(fecha) {
      moment.locale("es");
      return moment(fecha).format("dddd, D [de] MMMM [de] YYYY h:mm A");
    },
    numeroFormateado(numero) {
      const num = parseFloat(numero);
      return num.toLocaleString("en-US", { minimumFractionDigits: 2 });
    }
  },
  mounted() {
    this.$store.state.Cargando = true;
    this.sucursal = this.$store.state.userSucursal ? this.$store.state.userSucursal : 0;
    if (this.salida !== null && this.regreso !== null) {
      this.$apollo.query({
        query: CONTRATADOS,
        variables: {
          salida: this.salida,
          regreso: this.regreso,
          sucursal: this.sucursal,
          categoria: this.categoria_id
        }
      }).then(response => {
        this.vehiculosContratados = response.data.vehiculosReservados;
        if (this.vehiculosContratados.length === 0) {
          this.$store.state.Cargando = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "No existen registros"
          });
        }
      });
    } else {
      this.$apollo.query({
        query: ALL_CONTRATADOS
      }).then(response => {
        this.vehiculosContratados = response.data.contratoPerName;
        if (this.vehiculosContratados.length === 0) {
          this.$store.state.Cargando = false;
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: "error",
            title: "No existen registros"
          });
        }
      });
    }
  }
};
</script>

<style scoped>
.input-icon {
  background-image: url('../../assets/img/search.png');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 20px;
  padding-left: 38px; /* ajusta el padding para que el texto no se solape con el icono */
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  width: 70%;
}

.modal {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.modal.show {
  opacity: 1;
}

@media (max-width: 550px) {
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 15px;
    width: 90%;
  }
}
</style>
